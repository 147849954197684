<template>
  <v-container
      id="projects"
      fluid
      align="center"
  >

    <base-material-card
        color="secondary"
        icon="mdi-cloud"
        inline
        title="SAAS Projects"
    >
      <template v-if="loading">
        <v-progress-linear
            indeterminate
            color="primary"
        ></v-progress-linear>
        <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
      </template>
      <v-row v-else>
        <v-col cols="12" v-if="!apiCalled || projects.length > 0">
          <v-row no-gutters>
            <v-col cols="6"></v-col>
            <v-col cols="6">
              <v-card
                  class="mx-auto"
                  outlined
              >
                <v-list-item three-line disabled>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1">
                      Need Help ?
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-sm-body-1">
                      Navigate to the official M360 Documentation and
                      learn how to install and run an <strong>M360 Gateway</strong> after you create a
                      <strong>SAAS
                      Project</strong>.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-card-text class="text-right text-sm-body-1">
                  Documentation
                  <a href="https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/564690945"
                     target="_blank">
                    Click Here
                  </a>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-card-title>
            <v-btn
                v-if="canAccess({module: 'saas', method: 'get', route: '/project'})"
                class="mr-2"
                dark
                small
                color="purple"
                @click="listProjects();"
            >
              <v-icon color="white">mdi-refresh</v-icon>
            </v-btn>
            <v-btn
                v-if="canAccess({module: 'saas', method: 'post', route: '/project/:type'})"
                class="mr-2"
                dark
                small
                color="success"
                @click="createProject()"
            >
              <v-icon color="white" class="mr-1">mdi-plus</v-icon>
              Create
            </v-btn>
            <v-btn
                v-if="projects.length > 0 && canAccess({module: 'saas', method: 'delete', route: '/project/:id'})"
                color="error"
                dark
                small
                @click="multiDeleteProjects()"
            >
              <v-icon class="mr-1" color="white">mdi-delete</v-icon>
              Delete
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                v-if="projects.length > 0"
                append-icon="mdi-magnify"
                label="Search"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :loading="projects.length === 0"
              loading-text="Loading... Please wait"
              v-model="selected"
              :headers="headers"
              :items="projects"
              :search="search"
              :single-expand="false"
              :expanded.sync="projects.expanded"
              :show-expand="canAccess({module: 'saas', method: 'get', route: '/project/:tCode/download/:file'})"
              :show-select="projects.length > 0"
              item-key="id"
              :sort-by="defaultSortKey"
          >

            <template v-slot:item.type="{ item }">
              <v-chip
                  v-if="item.subscription"
                  label
                  :color="item.subscription.color"
                  class="white--text"
                  :class="item.subscription.colorVariation"
              >
                {{ item.subscription.package }}
              </v-chip>
            </template>

            <template v-slot:item.name="{ item }">
              {{ item.name }}
            </template>

            <template v-slot:item.code="{ item }">
              <v-chip
                  class="ml-1"
                  :color="item.codeColor"
                  dark
                  small
                  label
              >
                {{ item.code.toUpperCase() }}
              </v-chip>
            </template>

            <template v-slot:item.subscription="{ item }">
              <v-progress-linear
                  v-if="item.status !=='ready'"
                  :color="item.codeColor"
                  height="25"
                  :striped="item.status==='pending'"
                  :indeterminate="item.status==='pending'"
                  class="text-capitalize"
              >{{ item.status }}
              </v-progress-linear>
              <span v-else>
                <v-chip>
<!--                      {{ new Date(item.subscription.validTillTs).toDateString() }}-->
                    {{ new Date(item.subscription.expDate).toDateString() }}
                </v-chip>
                  </span>
            </template>

            <template v-slot:item.actions="{ item }">

              <v-tooltip top v-if="item.status !=='pending'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      v-if="canAccess({module: 'saas', method: 'delete', route: '/project/:id'})"
                      color="error"
                      fab
                      x-small
                      class="ml-2 float-right"
                      @click="deleteProject(item)"
                  >
                    <v-icon color="white" x-small>mdi-delete</v-icon>
                  </v-btn>

                </template>
                <span>Delete Project</span>
              </v-tooltip>

              <v-tooltip top v-if="item.status ==='ready' ">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      v-if="canAccess({module: 'saas', method: 'put', route: '/project/:id/:mode'})"
                      color="success"
                      fab
                      x-small
                      class="ml-2 float-right"
                      @click="editProject(item)"
                  >
                    <v-icon color="white" x-small>mdi-pencil</v-icon>
                  </v-btn>

                </template>
                <span>Edit Project</span>
              </v-tooltip>

              <v-tooltip top v-if="item.status ==='ready'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="float-right ml-2"
                      color="primary"
                      fab
                      x-small
                      @click="openProject(item)"
                  >
                    <v-icon color="white" x-small>
                      mdi-folder-open
                    </v-icon>
                  </v-btn>
                </template>
                <span>Open Project</span>
              </v-tooltip>

              <v-tooltip top v-if="item.status ==='fail'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs"
                         v-on="on"
                         class="float-right"
                         v-if="canAccess({module: 'saas', method: 'get', route: '/project/retry/:id'})"
                         color="orange"
                         fab
                         x-small
                         @click="retryProject(item)">
                    <v-icon color="white" small>
                      mdi-play-speed
                    </v-icon>
                  </v-btn>
                </template>
                <span>Retry Create Project</span>
              </v-tooltip>

            </template>

            <template v-slot:item.data-table-expand="{item, isExpanded, expand}">
              <v-btn x-small icon @click="expand(true)" v-if="item.status ==='ready' && !isExpanded">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
              <v-btn x-small icon @click="expand(false)" v-if="item.status ==='ready' && isExpanded">
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td v-if="item.status ==='ready'" :colspan="headers.length" class="pa-5 text-center">

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left" width="10%">
                        Type
                      </th>
                      <th class="text-left" width="20%">
                        Name
                      </th>
                      <th class="text-left" width="20%">
                        Last Modified
                      </th>
                      <th class="text-left" width="10%">
                        Size
                      </th>
                      <th class="text-right" width="30%">
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="(oneFile, i) in item.files"
                        :key="i"
                    >
                      <td class="text-capitalize text-left" width="10%">
                        <v-chip
                            label
                            small
                            color="success"
                        >
                          {{ oneFile.type }}
                        </v-chip>
                      </td>
                      <td class="text-left" width="20%">{{ oneFile.name }}</td>
                      <td class="text-left" width="20%">{{ new Date(oneFile.LastModified).toDateString() }}</td>
                      <td class="text-right" width="10%">{{ oneFile.Size | prettyBytes }}</td>
                      <td class="text-right" width="30%"
                          v-if="canAccess({module: 'saas', method: 'get', route: '/project/:tCode/download/:file'})">
                        <v-btn
                            :key="i"
                            color="success"
                            x-small
                            class="white--text"
                            @click="downloadFile(oneFile, item)"
                        >
                          <v-icon
                              color="white"
                              x-small
                          >mdi-cloud-download
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>

          </v-data-table>
        </v-col>
        <v-col cols="12" v-else>
          <br/>
          <v-row>
            <v-col xs="12" sm="12" md="12" lg="6" xl="6">
              <v-alert
                  type="warning"
                  prominent
                  text
                  class="mt-8 pa-8 white--text">
                <v-row
                    align="center"
                    class="text-sm-body-1"
                >
                  <v-col xs="12" sm="12" md="12" lg="8" xl="8" class="grow">
                    <h3>No Project(s) Found!</h3>
                    <p>It appears that you don't have any SAAS projects created yet.</p>
                  </v-col>
                  <v-col xs="12" sm="12" md="12" lg="4" xl="4" class="shrink text-right">
                    <v-btn
                        v-if="projects.length === 0 && canAccess({module: 'saas', method: 'post', route: '/project/:type'})"
                        outlined
                        color="warning"
                        @click="createProject()"
                    >
                      <v-icon class="mr-1">mdi-plus</v-icon>
                      Create
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col xs="12" sm="12" md="12" lg="6" xl="6">
              <v-card
                  class="mx-auto"
                  outlined
              >
                <v-list-item three-line disabled>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1">
                      Need Help ?
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-sm-body-1">
                      Navigate to the official M360 Documentation and
                      learn how to install and run an <strong>M360 Gateway</strong> after you create a <strong>SAAS
                      Project</strong>.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-card-text class="text-right text-sm-body-1">
                  Documentation
                  <a href="https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/564690945"
                     target="_blank">
                    Click Here
                  </a>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-hover
                  v-slot="{ hover }"
                  open-delay="50"
              >
                <v-card
                    elevation="2"
                    class="pt-1"
                    :color="hover ? 'secondary': ''"
                >
                  <v-card-title class="mb-3" :class="{'secondary--text': !hover}">
                    <v-icon class="mr-2" :class="{'secondary--text': !hover}">mdi-cloud</v-icon>
                    M360API &reg; SAAS
                  </v-card-title>
                  <v-card-text :class="(hover) ? 'white--text': ''">
                    Get Started building your project and select a package that fits your needs.
                  </v-card-text>
                  <v-list
                      id="saasFeatures"
                      :flat="true"
                      :tile="true"
                  >
                    <v-subheader>Features</v-subheader>
                    <v-list-item-group>
                      <v-list-item
                          v-for="(item, i) in saasFeatures"
                          :key="i"
                      >
                        <v-list-item-icon>
                          <v-icon :class="(hover) ? '': 'secondary--text'" v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title :class="(hover) ? 'white--text': ''"
                                             v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-hover>
            </v-col>

          </v-row>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import {mapGetters} from "vuex";

const data = {
  loading: true,
  search: '',
  headers: [
    {
      'value': 'type', 'text': 'Package',
      'sortable': true
    },
    {
      'value': 'name', 'text': 'Name', 'sortable': true,
    },
    {
      'value': 'description', 'text': 'Description', 'sortable': false
    },
    {
      'value': 'code', 'text': 'Code',
      'sortable': true, 'width': '12%',
    },
    {
      'value': 'subscription', 'text': 'Expires On',
      'width': '12%',
      'sortable': false,
    },
    {
      text: 'Actions', value: 'actions',
      width: '19%',
      sortable: false, align: 'end'
    },
    {text: '', value: 'data-table-expand'},
  ],
  defaultSortKey: ['type'],
  projects: [],
  selected: [],
  expanded: [],
  apiCalled: false
};

const methods = {

  async listProjects() {
    const self = this;
    this.loading = true;

    self.apiCalled = false;
    self.projects = [];
    this.clearMessages();
    self.getSendData({
      noLoading: true,
      url: '/saas/project',
      method: 'get',
      params: {
        "type": 'saas'
      }
    }).then(response => {
      if (response && Array.isArray(response)) {
        response.forEach((oneRow) => {
          oneRow.codeColor = 'success';
          switch (oneRow.status) {
            case 'pending':
              oneRow.codeColor = 'secondary';
              break;
            case 'fail':
              oneRow.codeColor = 'orange';
              break;
            case 'permanentFail':
              oneRow.codeColor = 'error';
              break;
          }
          if (oneRow.subscription && oneRow.subscription.package) {
            switch (oneRow.subscription.package.toLowerCase()) {
              case 'startup':
                oneRow.subscription.color = 'orange';
                oneRow.subscription.colorVariation = 'lighten-3';
                break;
              case 'smb':
                oneRow.subscription.color = 'blue';
                oneRow.subscription.colorVariation = 'lighten-3';
                break;
              case 'professional':
                oneRow.subscription.color = 'green';
                oneRow.subscription.colorVariation = 'lighten-3';
                break;
            }
          }

        });
        self.projects = response;
      }
      self.apiCalled = true;
      self.loading = false;
    }).catch(error => {
      self.apiCalled = true;
      self.loading = false;
      // this.handleErrorResponse(error);
    });

  },

  createProject() {
    this.$router.push({name: 'createProject', params: {type: 'saas'}})
  },

  editProject(project) {
    this.$router.push({name: 'editProject', params: {type: 'saas', id: project.id}})
  },

  async multiDeleteProjects() {
    const self = this;
    if (self.selected && self.selected.length > 0 && confirm('Are you sure you want to delete these projects, all their data will be lost ?')) {
      self.$emit('triggerOverlayLoading', true);

      await deleteOneProject(() => {
        self.$emit('triggerOverlayLoading', false);
        setTimeout(() => {
          self.listProjects();
        }, 500)
      })
    }

    async function deleteOneProject(cb) {
      await self.deleteProject(self.selected[0], true);
      self.selected.shift();
      if (self.selected.length > 0) {
        setTimeout(async () => {
          await deleteOneProject(cb);
        }, 1000);
      } else {
        return cb(true);
      }
    }
  },

  async deleteProject(project, multi = false) {
    const self = this;
    if (multi || confirm('Are you sure you want to delete this project, all project data will be lost ?')) {
      this.loading = true;
      self.getSendData({
        noLoading: multi,
        url: `/saas/project/${project.id}`,
        method: 'delete',
        params: {}
      }).then(response => {
        self.pushMessage({
          type: 'success',
          title: 'Project Deleted',
          text: `Project ${project.name} has been successfully deleted.`
        });
        this.loading = false;
        setTimeout(() => {
          self.listProjects();
        }, 1000);
      }).catch(error => {
        this.loading = false;
        this.handleErrorResponse(error);
      });
    }
  },

  openProject(project) {
    let token = this.token;
    let projectLink = `${process.env.VUE_APP_M360_CONSOLE_UI}?` +
        `project=${project.code}`;

    let myDate = new Date();
    myDate.setMinutes(myDate.getMinutes() + 5);

    let cookie1 = "access_token=" + token.access_token + ";expires=" + myDate + `;domain=.${process.env.VUE_APP_M360_DOMAIN};path=/`;
    let cookie2 = "refresh_token=" + token.refresh_token + ";expires=" + myDate + `;domain=.${process.env.VUE_APP_M360_DOMAIN};path=/`;

    document.cookie = cookie1;
    document.cookie = cookie2;

    window.open(projectLink, '_blank');
  },

  downloadFile(oneFile, project) {
    const self = this;
    self.getSendData({
      url: `/saas/project/${project.code}/download/${oneFile.name}`,
      method: 'get',
      // blob: true,
      params: {}
    }).then(response => {
      window.open(`${process.env.VUE_APP_M360_DOWNLOAD}/project/${project.code}/download/${response}`);
    }).catch(error => {
      this.handleErrorResponse(error);
    });
  },

  retryProject(project) {
    const self = this;
    if (confirm('Are you sure you want to retry creating this project?')) {
      self.getSendData({
        url: `/saas/project/retry/${project.id}`,
        method: 'get',
        params: {}
      }).then(response => {
        self.pushMessage({
          type: 'success',
          title: 'Project Create',
          text: `Project ${project.name} has been rescheduled for creation. It May take a few minutes for this project to become ready.`
        });
        setTimeout(() => {
          self.listProjects();
        }, 500);
      }).catch(error => {
        this.handleErrorResponse(error);
      });
    }
  }

};

export default {
  name: 'saaSProjects',
  methods: methods,
  props: {
    saasFeatures: {
      type: Array
    }
  },
  components: {},

  mixins: [globalMixins],

  filters: {
    prettyBytes: function (num) {
      // jacked from: https://github.com/sindresorhus/pretty-bytes
      if (typeof num !== 'number' || isNaN(num)) {
        throw new TypeError('Expected a number');
      }

      let exponent;
      let unit;
      let neg = num < 0;
      let units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      if (neg) {
        num = -num;
      }

      if (num < 1) {
        return (neg ? '-' : '') + num + ' B';
      }

      exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
      num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
      unit = units[exponent];

      return (neg ? '-' : '') + num + ' ' + unit;
    }
  },

  async created() {
    await this.listProjects();
  },

  data: () => (data),

  computed: {
    ...mapGetters({user: 'auth/getUser', token: 'auth/getToken'}),
  }
}
</script>
